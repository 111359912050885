import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
// import {Link} from "react-router-dom";
import Tilt from 'react-parallax-tilt';
// import { Progress } from 'reactstrap';
import Cop_Sec_1 from '../../assets/images/skill/cop-sec-1.jpeg'
import Acc_Serv_1 from '../../assets/images/skill/Accouting-services-1.jpeg'
import Tax_1 from '../../assets/images/skill/tax-1.jpeg'
import Manpower_imigra from '../../assets/images/skill/man-imigra-1.jpeg'
import It_service_1 from '../../assets/images/skill/It-service-1.jpeg'
import Parallax from 'parallax-js';


const AboutSix = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <>
            <div className="section section-padding-top technology-section-padding-bottom-180" id="Corporate">
                <div className="container">

                    <div className="row">

                        <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                            <div className="about-image-area">
                                <div className="about-image">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                    {/* <img src={process.env.PUBLIC_URL + "/images/skill/skill-1.jpg"} alt="" /> */}
                                    <img src={Cop_Sec_1} alt="" />

                                    </Tilt>
                                </div>
                                <div className="about-image">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                    {/* <img src={process.env.PUBLIC_URL + "/images/skill/skill-2.jpg"} alt="" /> */}

                                    </Tilt>
                                </div>
                                <div className="shape shape-1" id="scene" ref={sceneEl}>
                                    <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/about-shape-1.png"} alt="" /></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                            <div className="about-content-area">
                                <SectionTitleTwo

                                    //  subTitle="Innovative &amp; cutting-edge technology"
                                    title="Corporate Secretarial Services"
                                />
                                <div className="Corporate_Secretarial-list">
                                    <ul className="Corporate_Secretarial-service">
                                        <li>
                                            <i className="pe-7s-check"></i>
                                            <span>Amendment of company particulars and Officers</span>
                                        </li>
                                        <div>

                                            <li>
                                                <i className="pe-7s-check"></i>
                                                <span >Preparation and filing of Annual General Meeting (AGM) & Annual Return Filing (AR)</span></li>

                                        </div>
                                        <li><i className="pe-7s-check"></i>
                                            <span >Email Reminders for Government Filing Deadlines</span></li>
                                    </ul>
                                    <p>We assist you to amend company particulars, Company Officers, Preparation and filing of Annual General Meeting (AGM), Annual Return Filing (AR) and Email Reminders for Government Filing Deadlines.</p>
                                    <h6>We assist the full range of services includes:</h6>

                                    <ul className="Corporate_Secretarial-service-two">
                                        <li>
                                            <i className="pe-7s-check"></i>
                                            <span>Registration of sole-proprietor and partnership businesses</span>
                                        </li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Registration of Limited Liability Partnership (LLP)</span></li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Incorporation of Private Limited companies (Pte Ltd)</span></li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Striking off of Companies</span></li>

                                    </ul>

                                </div>
                                {/* <p>Our goal is to make the process easier for you. We provide our clients with the right tools for web development, hosting, e-commerce, security, content and marketing.</p> */}

                                {/* <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/"}>Get Started</Link> */}
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div className="section section-padding-top skill-section-padding-bottom bg-primary-blue" data-bg-color="#f8faff" id="Accounting">
                <div className="container">
                    <div className="row">

                        <div className="col-xl-5 col-lg-6 order-lg-1 order-2" data-aos="fade-up">

                            <div className="progressbar-area mt-lg-0 mt-md-50 mt-sm-50 mt-40">

                                <SectionTitleTwo
                                    // subTitle="Brand-first creative services"
                                    title="Accounting Services"
                                />

                                {/* <div className="progress-bar--one">
                            <div className="progress-charts">
                                <h6 className="heading ">UX Design</h6>
                                <div className="single-progress">
                                    <Progress
                                    barClassName="gradient-1"  
                                    value="81" >
                                        <span className="percent-label">81%</span>
                                    </Progress>
                                </div>
                            </div>

                            <div className="progress-charts ">
                                <h6 className="heading ">Marketing</h6>
                                <div className="single-progress">
                                    <Progress barClassName="gradient-2"  value="72" >
                                        <span className="percent-label">72%</span>
                                    </Progress>
                                </div>
                            </div>

                            <div className="progress-charts ">
                                <h6 className="heading ">Web Design</h6>
                                <div className="single-progress">
                                    <Progress barClassName="gradient-3"  value="81" >
                                        <span className="percent-label">81%</span>
                                    </Progress>
                                </div>
                            </div>

                            <div className="progress-charts ">
                                <h6 className="heading ">Development</h6>
                                <div className="single-progress">
                                    <Progress barClassName="gradient-4" value="81" >
                                        <span className="percent-label">81%</span>
                                    </Progress>
                                </div>
                            </div>
                        </div> */}

                                <div className="Business_Management-Services"> <p>We provide and assist to do Accounting services for small and medium sized businesses with various Accounting Software.
                                </p>


                                </div>
                                <div className="Business_Management-list">
                                    <ul className="Business_Management-service">
                                        <div>
                                            <li>
                                                <i className="pe-7s-check"></i>
                                                <span>Preparation of Balance sheet, Profit & Loss statement, Cash flow statement etc.,</span>
                                            </li>
                                        </div>


                                        <li><i className="pe-7s-check"></i>
                                            <span >Affordable pricing to SME and entrepreneurs</span></li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Book keeping and accounting (monthly, quarterly & annually)</span></li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Preparation of Unaudited financial statement</span></li>


                                    </ul>
                                </div>

                            </div>

                        </div>

                        <div className="offset-xl-1 col-xl-6 col-lg-6 order-lg-2 order-1" data-aos="fade-up" data-aos-delay="300">
                            <div className="about-image-area right-0 skill-image-area">
                                <div className="about-image">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                        {/* <img src={process.env.PUBLIC_URL + "/images/skill/skill-1.jpg"} alt="" /> */}
                                        <img src={process.env.PUBLIC_URL + Acc_Serv_1} alt="" />
                                    </Tilt>
                                </div>
                                <div className="about-image">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                        {/* <img src={process.env.PUBLIC_URL + "/images/skill/skill-2.jpg"} alt="" /> */}
                                    </Tilt>
                                </div>
                                <div className="shape shape-1" id="scene" ref={sceneEl}>
                                    <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/video-shape-1.png"} alt="" /></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
            <div className="section section-padding-top technology-section-padding-bottom-180" id="Taxation">
                <div className="container">

                    <div className="row">

                        <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                            <div className="about-image-area">
                                <div className="about-image">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + Tax_1} alt="" />
                                    {/* <img src={process.env.PUBLIC_URL + "/images/skill/skill-1.jpg"} alt="" /> */}
                                    </Tilt>
                                </div>
                                <div className="about-image">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                    {/* <img src={process.env.PUBLIC_URL + "/images/skill/skill-2.jpg"} alt="" /> */}
                                    </Tilt>
                                </div>
                                <div className="shape shape-1" id="scene" ref={sceneEl}>
                                    <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/about-shape-1.png"} alt="" /></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                            <div className="about-content-area">
                                <SectionTitleTwo

                                    //  subTitle="Innovative &amp; cutting-edge technology"
                                    title="Taxation"
                                />
                                <div className="Corporate_Secretarial-list">
                                    <ul className="Corporate_Secretarial-service">
                                        <li>
                                            <i className="pe-7s-check"></i>
                                            <span>Free consultation on tax submission</span>
                                        </li>
                                        <div>
                                            <li><i className="pe-7s-check"></i>
                                                <span >Full range of tax services (Form C-S / Form C-S lite / Form B / Form P)</span></li>

                                        </div>

                                        <li><i className="pe-7s-check"></i>
                                            <span >GST Registration</span></li>
                                    </ul>

                                    <h6>The full range of services includes:</h6>

                                    <ul className="Corporate_Secretarial-service-two">
                                        <li>
                                            <i className="pe-7s-check"></i>
                                            <span>Individual - Form B</span>
                                        </li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Partnership - Form P</span></li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Company - Form C-S and C-S Lite</span></li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Estimated Chargeable Income (ECI)</span></li>
                                        <li><i className="pe-7s-check"></i>
                                            <span >GST Registration & GST submission</span></li>

                                    </ul>

                                </div>
                                {/* <p>Our goal is to make the process easier for you. We provide our clients with the right tools for web development, hosting, e-commerce, security, content and marketing.</p> */}

                                {/* <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/"}>Get Started</Link> */}
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div className="section section-padding-top skill-section-padding-bottom bg-primary-blue" data-bg-color="#f8faff" id="Manpower">
                <div className="container">
                    <div className="row">

                        <div className="col-xl-5 col-lg-6 order-lg-1 order-2" data-aos="fade-up">

                            <div className="progressbar-area mt-lg-0 mt-md-50 mt-sm-50 mt-40">

                                <SectionTitleTwo
                                    // subTitle="Brand-first creative services"
                                    title="Manpower and Immigration services"
                                />

                                {/* <div className="progress-bar--one">
                            <div className="progress-charts">
                                <h6 className="heading ">UX Design</h6>
                                <div className="single-progress">
                                    <Progress
                                    barClassName="gradient-1"  
                                    value="81" >
                                        <span className="percent-label">81%</span>
                                    </Progress>
                                </div>
                            </div>

                            <div className="progress-charts ">
                                <h6 className="heading ">Marketing</h6>
                                <div className="single-progress">
                                    <Progress barClassName="gradient-2"  value="72" >
                                        <span className="percent-label">72%</span>
                                    </Progress>
                                </div>
                            </div>

                            <div className="progress-charts ">
                                <h6 className="heading ">Web Design</h6>
                                <div className="single-progress">
                                    <Progress barClassName="gradient-3"  value="81" >
                                        <span className="percent-label">81%</span>
                                    </Progress>
                                </div>
                            </div>

                            <div className="progress-charts ">
                                <h6 className="heading ">Development</h6>
                                <div className="single-progress">
                                    <Progress barClassName="gradient-4" value="81" >
                                        <span className="percent-label">81%</span>
                                    </Progress>
                                </div>
                            </div>
                        </div> */}

                                <div className="Business_Management-Services"> <p>With our year of experience and knowledge of the process, we are able to serve our clients in the best possible manner for hassle-free and prompt Immigration Services. Our Immigration Services starts before the flight and continue even after the flight so that our services are suited to the needs of every individual client.
                                </p>

                                    <h6>Assist to submit the below applications:</h6>

                                </div>
                                <div className="Business_Management-list">
                                    <ul className="Business_Management-service">
                                        <div>
                                        <li>
                                            <i className="pe-7s-check"></i>
                                            <span>Employment Pass and `s` Pass , Work Permit and related work passes.</span>
                                        </li>
                                        </div>
                                      

                                        <li><i className="pe-7s-check"></i>
                                            <span >Dependent pass Application</span></li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Permanent Resident Application (PR) / PR Renewal</span></li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Singapore Citizenship Application</span></li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Entry Visa / Social Visa / Visa Extension etc.</span></li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >LOC Application</span></li>
                                        <li><i className="pe-7s-check"></i>
                                            <span >Long Term visit pass / LTVP+</span></li>
                                        <li><i className="pe-7s-check"></i>
                                            <span >FDW Application</span></li>


                                    </ul>
                                </div>

                            </div>

                        </div>

                        <div className="offset-xl-1 col-xl-6 col-lg-6 order-lg-2 order-1" data-aos="fade-up" data-aos-delay="300">
                            <div className="about-image-area right-0 skill-image-area">
                                <div className="about-image">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                        {/* <img src={process.env.PUBLIC_URL + "/images/skill/skill-1.jpg"} alt="" /> */}
                                        <img src={process.env.PUBLIC_URL + Manpower_imigra} alt="" />
                                    </Tilt>
                                </div>
                                <div className="about-image">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                        {/* <img src={process.env.PUBLIC_URL + "/images/skill/skill-2.jpg"} alt="" /> */}
                                    </Tilt>
                                </div>
                                <div className="shape shape-1" id="scene" ref={sceneEl}>
                                    <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/video-shape-1.png"} alt="" /></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
            <div className="section section-padding-top technology-section-padding-bottom-180" id="ITServices">
                <div className="container">

                    <div className="row">

                        <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                            <div className="about-image-area">
                                <div className="about-image">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + It_service_1} alt="" />
                                    {/* <img src={process.env.PUBLIC_URL + "/images/skill/skill-1.jpg"} alt="" /> */}

                                    </Tilt>
                                </div>
                                <div className="about-image">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                    {/* <img src={process.env.PUBLIC_URL + "/images/skill/skill-2.jpg"} alt="" /> */}

                                    </Tilt>
                                </div>
                                <div className="shape shape-1" id="scene" ref={sceneEl}>
                                    <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/about-shape-1.png"} alt="" /></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                            <div className="about-content-area">
                                <SectionTitleTwo

                                    //  subTitle="Innovative &amp; cutting-edge technology"
                                    title="IT Services"
                                />
                                <div className="IT-Services">
                                    <h6>Webpage designing</h6>

                                    <p>At Vinsoft we have the resources to serve our customers to solve a wide range of IT related services in terms of Web application, Windows application, Softwares and Mobile Application.</p>
                                    <p>Website and business E-mail is the best source to reach more clients and customers. It helps to enhance and develop the reputation for any organisation, hence, we provide professionals and backend support to design the websites.</p>

                                    <ul className="Corporate_Secretarial-service">


                                        <li>
                                            <i className="pe-7s-check"></i>
                                            <span>Domain Registration</span>
                                        </li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Hosting</span></li>

                                        <li><i className="pe-7s-check"></i>
                                            <span >Web Designing</span></li>
                                        <li><i className="pe-7s-check"></i>
                                            <span >Business Email setup</span></li>
                                    </ul>



                                </div>
                                {/* <p>Our goal is to make the process easier for you. We provide our clients with the right tools for web development, hosting, e-commerce, security, content and marketing.</p> */}

                                {/* <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/"}>Get Started</Link> */}
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default AboutSix;
