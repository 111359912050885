// import react from "react";
import Header from "../partials/header/Header";
import Footer from '../container/Footer/Footer';
import Payment from '../assets/images/payment/payment-qr.png'
import '../assets/scss/payment.scss'


const QRcodePayment = () => {
    return (
        <>
            <Header />
            <section>
                <div className="container">
                    <div className="payment-container" data-aos="fade-up" data-aos-delay="300">
                        <img src={Payment} />
                    </div>
                    <div className="download-qr-code">
                        <a href={Payment} download="Payment QR-Code"><button className="btn btn-primary btn-hover-success">Download QR-Code</button></a>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default QRcodePayment;