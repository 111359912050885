import React from 'react'

const GoogleMap = () => {
    return (
        <div className="google-map-area section text-center section-padding-bottom">
            <div className="container">
                <div className="contact-map-area" data-aos="fade-up">
                <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.783055804433!2d103.85030061475399!3d1.3052572990480005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x103a5700ba0aee08!2zMcKwMTgnMTguOSJOIDEwM8KwNTEnMDkuMCJF!5e0!3m2!1sen!2sin!4v1640089064812!5m2!1sen!2sin" aria-hidden="false"></iframe>
                    {/* <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2136.986005919501!2d-73.9685579655238!3d40.75862446708152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258e4a1c884e5%3A0x24fe1071086b36d5!2sThe%20Atrium!5e0!3m2!1sen!2sbd!4v1585132512970!5m2!1sen!2sbd"  aria-hidden="false"></iframe> */}
                </div>
            </div>
        </div>
    )
}

export default GoogleMap;
