/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react";
import SectionTitle from '../SectionTitles/SectionTitle';
import SectionTitleTwo from '../SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import { Link } from "react-router-dom";
import XeroCertified from '../../assets/images/about/xero/xero-certified-advisor-logo-hires-RGB.jpg'
import XeroPartner from '../../assets/images/about/xero/xero-partner-badge-CMYK.jpg'

const HomeAbout = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-t90 section-padding-bottom-200">
            <div className="container">

                <SectionTitle
                    title="We are a fast growing business management <br/>consulting company"
                    subTitle=" Our team is specialized in company formation/setup, registration, taxation, financial accounting and a complete support on IT services like Web designining, Software products & personalized mobile app development."
                />

                <div className="row">

                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>

                                    <img src={process.env.PUBLIC_URL + "/images/about/home-one-about/home_agency_about_1.jpg"} alt="" />
                                </Tilt>
                            </div>

                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "/images/about/home-one-about/home_agency_about_2.jpg"} alt="" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/about-shape-1.png"} alt="" /></span>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo
                                subTitle="Accounting Services"
                                title="About Our Company"
                            />


                            <ul className="li_section" >
                                <div className="about_conent">
                                    {/* <h1>One Stop Solution For All Your Business Needs!</h1> */}
                                    {/* <li>

                                        <i className="fal fa-check-circle"></i>
                                        <span>Preparation of Balance sheet, Profit & Loss statement, Cash flow statement etc.,</span>

                                    </li> */}
                                </div>
                                <div>
                                    <span>We assist you in complying with the ACRA company Registration, Filing of AGM, Annual Return, Taxation, ECI, IRAS yearly Filing requirements, CPF submission, EPOL & WPOL authorization, Application for E-pass, S-pass and other work permit related matters. We offer our promotional package for new companies if you sign up with us.</span><br /><br />
                                    <span>We also cover an extensive range of professional services like book-keeping, payroll management, and various corporate and business-related services, which aims to help SME, entrepreneurs and business owners to better allocate their resources on their core business components.</span>
                                </div>
                                {/* <li>
                                    <i className="fal fa-check-circle"></i>
                                    <span>Affordable pricing to SME and entrepreneurs</span>
                                </li>
                                <div>
                                    <li>
                                        <i className="fal fa-check-circle"></i>
                                        <span>Book keeping and accounting (monthly, quarterly & annually)</span>
                                    </li>
                                </div>

                                <li>
                                    <i className="fal fa-check-circle"></i>
                                    <span>Preparation of Unaudited financial statement</span>
                                </li> */}
                            </ul>


                            <Link className="btn btn-primary btn-hover-secondary mt-xl-8 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/about"}>About Us</Link>
                        </div>
                    </div>

                </div>
                <div className="row">

                    <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo
                                subTitle="Accounting Services"
                                title="Testimonials"
                            />


                            <ul className="li_section" >
                                <div className="about_conent">
                                </div>
                                <div className="testimonial">
                                    <span className="testimonial_span"><span>1</span>We are extremely happy and satisfied with the accounting services at vinsoft. Vinsoft is the best choice for small scale entrepreneurs like us, to have high quality services at affordable pricing.</span><br /><br />
                                    <span className="testimonial_span"><span>2</span>Vinsoft is our one stop solution for all tax related concerns. The consultants provide up-to-date insights on taxation and GST registration. Thank you vinsoft for your step by step guidance and authenticated service.</span><br /><br />
                                    <span className="testimonial_span"><span>3</span>The consultants are friendly and easy to approach. They offer best and relevant business solutions.</span><br /><br />
                                    <span className="testimonial_span"><span>4</span>Vinsoft had made my business registration process simple and haste free. I was looking for guidance to file annual returns, vinsoft offered step by step guidance and made it easy at an affordable cost.</span><br /><br />
                                    <span className="testimonial_span"><span>5</span>Vinsoft helped me to expand my business and reach out progressive and large volume of customers through their webpage designs for my business.</span><br /><br />
                                    <span className="testimonial_span"><span>6</span>With the timely help of immigration services offered by vinsoft, finally my dream of living with my family came true. They offer timely assistance with regards to immigration knowing our emergency.</span><br /><br />
                                </div>
                            </ul>


                            {/* <Link className="btn btn-primary btn-hover-secondary mt-xl-8 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/about"}>About Us</Link> */}
                        </div>
                    </div>
                    <div className="xero-images">
                        <img src={XeroCertified} />
                        <img src={XeroPartner} />
                    </div>

                </div>

            </div>
        </div>
    )
}

export default HomeAbout;
