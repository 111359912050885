import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import { Link } from "react-router-dom";
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const AboutFive = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top section-padding-bottom-180">
            <div className="container">

                <div className="row">

                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "/images/about/about-3.jpg"} alt="" />
                                </Tilt>
                            </div>
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "/images/about/about-4.jpg"} alt="" />
                                </Tilt>
                            </div>

                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/about-shape-1.png"} alt="" /></span>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo
                                subTitle="Our solutions begin with brand research"
                                title="About Our Company"
                            />

                            <ul className="li_section" >
                                <div className="about_conent">
                                    {/* <h1>One Stop Solution For All Your Business Needs!</h1> */}
                                    {/* <li>

                                        <i className="fal fa-check-circle"></i>
                                        <span>Preparation of Balance sheet, Profit & Loss statement, Cash flow statement etc.,</span>

                                    </li> */}
                                </div>
                                <div>
                                    <span>We assist you in complying with the ACRA company Registration, Filing of AGM, Annual Return, Taxation, ECI, IRAS yearly Filing requirements, CPF submission, EPOL & WPOL authorization, Application for E-pass, S-pass and other work permit related matters. We offer our promotional package for new companies if you sign up with us.</span><br /><br />
                                    <span>We also cover an extensive range of professional services like book-keeping, payroll management, and various corporate and business-related services, which aims to help SME, entrepreneurs and business owners to better allocate their resources on their core business components.</span>
                                </div>
                                {/* <li>
                                    <i className="fal fa-check-circle"></i>
                                    <span>Affordable pricing to SME and entrepreneurs</span>
                                </li>
                                <div>
                                    <li>
                                        <i className="fal fa-check-circle"></i>
                                        <span>Book keeping and accounting (monthly, quarterly & annually)</span>
                                    </li>
                                </div>

                                <li>
                                    <i className="fal fa-check-circle"></i>
                                    <span>Preparation of Unaudited financial statement</span>
                                </li> */}
                            </ul>
                            <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/"}>Get Started</Link>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutFive;
