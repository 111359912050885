import React from 'react';
// import {Link} from "react-router-dom";
import Logo from '../../components/logo/Logo';
import FooterData from '../../data/Footer/footerItem.json';
import FooterLinkItem from '../../components/Footer/FooterLinkItem.jsx';

const Footer = () => {
    return (
        <div className="footer-section section footer-bg-color">
            <div className="container">
                <div className="row mb-lg-14 mb-md-10 mb-6">

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <Logo
                                    image={`${process.env.PUBLIC_URL}/images/logo/vinsoft logo.png`}
                                />
                            </div>
                            <div className="footer-widget-content">
                                <div className="footer-social-inline">
                                    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter-square"></i></a>
                                    <a href="https://www.facebook.com/profile.php?id=100086109969196&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></a>
                                    <a href="https://instagram.com/vinsoft2020?igshid=ZDdkNTZiNTM=" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {FooterData && FooterData.map((single, key) => {
                        return (
                            <div key={key} className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                                <FooterLinkItem data={single} key={key} />
                            </div>
                        );
                    })}
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-6 footer_link">
                    <h5>contact us</h5>
                        <div className="header">
                            <div className="contact">
                                <ul className="contact">
                                    <li className="contac">
                                        <a href="https://api.whatsapp.com/message/IRNJTCMBEYN2E1" target="blank" className="phonelink01">
                                            <i className="fal fa-phone-alt"></i>
                                        </a>
                                        <span>
                                            <a href="https://api.whatsapp.com/message/IRNJTCMBEYN2E1"  target="blank"  className="phonelink02">+65 8710 5544<></></a>

                                            {/* <a href="https://api.whatsapp.com/message/IRNJTCMBEYN2E1"  target="blank" className="phonelink03">8243 9982</a> */}
                                        </span>
                                    </li>
                                    <li>
                                        <a href="https://goo.gl/maps/XuXoNk9QDiDPD41bA " target="blank ">
                                            <i className="fal fa-map-marker-alt"></i>
                                        </a>
                                        <a href="https://goo.gl/maps/XuXoNk9QDiDPD41bA " target="blank " className="phonelink04">
                                            <span>36, Campbell Lane, Singapore 209908</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="mail_addres">
                                <a href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=admin@vinsoft.com.sg" target="_blank" rel="noreferrer" className="phonelink05">
                                    <i className="fal fa-envelope"></i>
                                </a>
                                <span>
                                    <a href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=admin@vinsoft.com.sg" target="_blank" rel="noreferrer" className="phonelink06">admin@vinsoft.com.sg /</a><br/>
                                    <a href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=vinsoft2020@gmail.com" target="_blank" rel="noreferrer" className="phonelink07">vinsoft2020@gmail.com</a>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col">
                        <p className="copyright">Copyrights 2022- Vinsoft Private limited - All rights reserved</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer
